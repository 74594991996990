import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"

import { Col, Container, Row } from "react-bootstrap"

const EvBenefitsDetails = () => {
  return (
    <section className="ev-benefit-details">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <ol className="num-round color-3">
              <li>
                <strong>They build trust and credibility</strong>: To earn the
                trust of your customer, you need to engage with them. They need
                to feel that you have made an effort to reach out to them.
                Nothing is more engaging than a killer explainer video to show
                the customer that you have taken the effort to explain things to
                them.
              </li>

              <li>
                <strong>They boost sales & conversions</strong>: The purpose of
                having a video marketing campaign is to drive the customer to
                take some sort of action like a product purchase or email
                subscription. Explainer videos are a great way to do so as the
                customer gets a video preview of what to expect after buying
                into a product or service.
              </li>

              <li>
                <strong>
                  They convey a lot more information in a short span of time
                </strong>
                : According to Forrester Research, a minute of video is worth
                1.8 Million words. Putting this into perspective, a one minute
                video has the same influential power as 900 blog posts of 2000
                words each.{" "}
              </li>

              <li>
                <strong>
                  They improve <a href="#email">email</a> marketing campaigns
                </strong>
                : According to Syndacast, just by including the word explainer
                video in the subject line, you can boost open rates by 19% and
                click-through rates by 65%. These are perfect for nurturing
                leads.
              </li>

              <li>
                <strong>
                  They{" "}
                  <a href="/blog/reduce-bounce-rate/" target="_blank">
                    reduce the bounce
                  </a>{" "}
                  rate of your webpages
                </strong>
                : By adding an explainer video, your visitors will spend more
                time on your website and could compel them to engage more around
                your site.
              </li>

              <li>
                <strong>They improve SEO rankings</strong>: User engagement rate
                and time spent on a website is an important search ranking
                signal. Since viewers spend more time on your website by
                watching videos, search engines like Google will take your
                website is a useful one. This will help you rank better & drive
                in more organic traffic to your website.
              </li>

              <li>
                <strong>
                  Videos have a big reach on social media platforms
                </strong>
                : By having an explainer video you can reach a much wider
                audience on social media, since{" "}
                <a href="#social-media">social media</a> are all about videos
                today.
              </li>

              <li>
                <strong>They are more engaging & compelling</strong>: A good
                explainer appeals to the emotion of the viewer. It highlights
                the difficulties that customers face and offers solutions to
                your problem. People are inspired to take action not based on
                fact, but based on emotion.
              </li>

              <li>
                <strong>
                  They are more human and they show the personality of the brand
                </strong>
                : The style and the messaging of explainer videos are very
                simple and humane. Customers can easily relate to them. Having
                one also gives you the chance to showcase your brand persona.
              </li>
            </ol>
          </Col>
          <Col lg={12}>
            <div className="expert-quote text-center" id="cyrus-shepard">
              <p className="text-left">
                Over the years I've made dozens of videos that have literally
                been watched 100s of thousands of times. Video doesn't replace
                text copy by any means, but often fills needs that text can't,
                and has several advantages.
              </p>
              <p className="text-left">
                The specific reasons I like making + using videos:
                <ol
                  className="text-left"
                  style={{ listStylePosition: "inside", marginTop: "10px" }}
                >
                  <li>They are highly shareable.</li>
                  <li>Snippets can be cut down for Twitter and Facebook.</li>
                  <li>Provides you with an endless supply of gifs.</li>
                  <li>
                    Multiple distribution platforms. A video hosted on your own
                    site can be uploaded to YouTube with a different description
                    to capture a broader audience segment.
                  </li>
                  <li>
                    People who won't take the time to read will often watch a
                    video.
                  </li>
                  <li>
                    Transcripts are incredibly easy and cheap, and can provide
                    you with text that not only helps your SEO, but allows
                    people to read along with of the video.
                  </li>
                  <li>
                    Videos provide an enhanced opportunity for personal branding
                    that doesn't exist with text. Seeing a person in video is
                    often much more powerful+personal than writing about the
                    person.
                  </li>
                </ol>
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/cyrus-shepard.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Cyrus Shepard"
                  className="rounded-circle"
                />
                <p className="expert-name">Cyrus Shepard</p>
                <p className="info">
                  Former Head of SEO & Content at
                  <a href="https://moz.com/" target="_blank" rel="noreferrer">
                    Moz
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="joe-elliott">
              <p>"Let's face it video is sweeping the internet!</p>
              <p>
                You don't want to be left behind. Creating an explainer video is
                a great way to capitalize on this. If getting in front of the
                camera is not for you then an animation with you as the voice
                over could be a great option.
              </p>
              <p>
                Video is the closest connection our audience can have with us.
                They can see how you act, they can hear your voice, and they can
                see your emotions.
              </p>
              <p>
                How many times have you got a text message from someone and
                wondered are they annoyed, happy or sad?
              </p>
              <p>With video, this is not an issue!"</p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/joe-elliott.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Joe Elliott"
                  className="rounded-circle"
                />
                <p className="expert-name">Joe Elliott</p>
                <p className="info">Founder of One Man Wiki</p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="lou-bortone">
              <p>
                There are plenty of reasons why explainer videos have become so
                effective. As a society raised on television and the Internet,
                we’re culturally conditioned to “watch” rather than read. In
                fact, according to{" "}
                <a
                  href="https://www.emailaudience.com/research-picture-worth-1000-words-marketing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  emailaudience.com
                </a>
                , “Videos are processed by the brain 60,000 times faster than
                text.” Meanwhile, digital agency Zabisco says that “40% of
                people will respond better to visual information than plain
                text.”
              </p>

              <p>
                However, I believe there’s an even bigger reason why Explainer
                Videos are so popular: Because it gets us off the hook for
                having to be on camera! Most folks would rather have a root
                canal than appear on camera, and off-camera explainer videos
                allow us to hide behind the visual{" "}
                <a
                  href="/blog/storytelling/"
                  target="_blank"
                >
                  storytelling power
                </a>{" "}
                of animation. As a content creator, I’d much prefer creating an
                explainer video than firing up my webcam!
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/lou-bortone.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Lou Bortone"
                  className="rounded-circle"
                />
                <p className="expert-name">
                  <a
                    href="https://loubortone.lpages.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lou Bortone
                  </a>
                </p>
                <p className="info">Video Marketing Expert</p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                 <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <p className="wrapper-main text-left">So how do explainer videos work? What are the steps involved in creating one?</p>
      </Container>
    </section>
  )
}

export default EvBenefitsDetails
