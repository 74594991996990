import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvCostDetails = () => {
  return (
    <section className="ev-cost-details">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <p className="font-weight-bold">
              Internet Videos can be uploaded and shared for FREE.
            </p>
            <p>
              Think about it. If you want to send your message across any other
              channel like television, radio or magazines:
            </p>

            <ul>
              <li>You have to pay to get it out there.</li>
              <li>
                You have to pay for however long you want your message out
                there.
              </li>
              <li>
                Your advertising campaign will die when you pull the plug on the
                payment; never to be used again.
              </li>
            </ul>

            <p className="font-weight-bold">Contrast that to Internet Videos.</p>

            <ul>
              <li>Free to upload.</li>
              <li>Free forever.</li>
              <li>
                Your video content will stay forever until the day the internet
                shuts down.
              </li>
            </ul>

            <p>
              Now if you are looking to hire an{" "}
              <a
                href="https://www.wowmakers.com"
                target="_blank"
                rel="noreferrer"
              >
                explainer video production agency
              </a>
              , it’s going to cost you anywhere between $5k to $30k.
            </p>
            <p>
              If you think about it, that’s pretty cheap compared to other
              advertising medium budgets. But this pricing depends on a lot on
              the type of video (animation or live action) and whether you will
              be using professional actors.
            </p>
            <p>
              Here is an overview of the average pricing types of explainer
              videos
            </p>
            <StaticImage
              src="../../images/explainer-video/explainer-video-cost.jpeg"
              alt="Production cost of an explainer video"
              width={1000}
              height={400}
              placeholder="blurred"
            />
          </Col>
          <Col lg={12}>
            <div className="expert-quote text-center" id="cameron-seher">
              <p>
                Businesses, agencies, and individuals need to invest in video marketing for myriad reasons, but the two big ones are that video is more personal. Not only does it level up your ability to tell stories, but it opens a window directly to the people telling your story: you!
              </p>
              <p>
                Second, as social, and media platforms have evolved, so has the video content and it's ubiquity. And not simply video over text, but different kinds of video for different kinds of consumption. Short form, spontaneous, and less polished social videos communicate personality and experience, where advances in drone, 3D/360º and VR tech mean that high quality production of brand and marketing videos are more affordable and attainable.
              </p>
              <p>
                Not to mention more user friendly editing tools. With the reach and amplification possible across social platforms, you no longer need a super bowl slot to reach thousands. So delivering them an impactful, message in the form of personal, and emotive video content is a no-brainer.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/cameron-seher.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Cameron Seher"
                  className="rounded-circle"
                />
                <p className="expert-name">Cameron Seher</p>
                <p className="info">
                  Community Manager at
                  <a
                    href="https://inbound.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Inbound.org
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>          
        </Row>
      </Container>
    </section>
  )
}

export default EvCostDetails
