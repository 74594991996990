import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvWhyVideoMarketing = () => {
  return (
    <section className="ev-why-video-marketing">
      <Container>
        <Row className="justify-content-center">
          <h3 className="col-lg-12 text-center">
            So, Why Should You Use Video Marketing?
          </h3>
          <Col lg={10} className="wrapper-main">
            <ol className="num-round color-2">
              <li>
                <strong>Videos boost sales and conversion.</strong> Studies show
                that adding a product video on your{" "}
                <a href="#landing-page">landing page</a> can increase
                conversions by 80%.
              </li>
              <li>
                <strong>
                  Videos help build trust and reliability of your brand.
                </strong>{" "}
                When customers engage with videos, they see the human side of
                your business. 57% of consumers say that videos gave them more
                confidence to purchase online.
              </li>
              <li>
                <strong>Videos help boost search engine rankings. </strong>They
                allow you to increase the time spent by visitors on your site.
                Longer time spent on your website is a signal to search engines
                that your site has good content. You’re 53 times more likely
                show up first on Google if you have a video embedded on your
                website.
              </li>
              <li>
                <strong>Videos connect well with mobile users</strong>. YouTube
                reports mobile video consumption rises by 100% every year. Since
                people like to watch videos on the go, and the amount of
                smartphone users is growing, the video audience keeps getting
                bigger.
              </li>
              <li>
                <strong>
                  Explainer Videos are the most effective way to explain a new
                  product, service or any business message.
                </strong>
              </li>
            </ol>
            <p>
              Sources:&nbsp;
              <a
                href="http://www.insivia.com/50-must-know-stats-about-video-marketing-2016/"
                target="_blank"
                rel="noreferrer"
              >
                Insivia
              </a>
              , &nbsp;
              <a
                href="http://www.e-tailing.com/content/wp-content/uploads/2013/03/EtailingGroup_Whitepaper_3.pdf"
                target="_blank"
                rel="noreferrer"
              >
                e-tailing;
              </a>
            </p>
          </Col>
          <Col lg={12}>
            <div className="expert-quote text-center" id="neil-patel">
              <p>
                Video marketing is exploding in popularity and will eventually
                be the most popular way to market a business. Because video is a
                visual medium, videos are extremely effective when it comes to
                improving people's understanding of your product or service.
              </p>
              <p>
                And with technology improving each and every day, no longer does
                a company need to hire a production team to produce a quality
                video. Today it is possible to produce a high-quality video
                using a cell phone. Not only do videos grab the viewer's
                attention, they get more exposure on social media than articles.
                While I haven't really incorporated a lot of video into my
                marketing strategies, I definitely plan on doing so in the near
                future.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/neil-patel.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Neil Patel"
                  className="rounded-circle"
                />
                <p className="expert-name">Neil Patel</p>
                <p className="info">
                  Founder of &nbsp;
                  <a
                    href="https://www.kissmetrics.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    KISSmetrics
                  </a>
                  , Co-Founder of &nbsp;
                  <a
                    href="https://www.crazyegg.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Crazy Egg
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="bruce-clay">
              <p>
                Google was the first engine to combine videos, images and other
                non-text assets into its search results (years ago with
                Universal search), and today these items continue to provide SEO
                benefits. Videos give a business more potential visibility in
                organic search. Particularly in mobile, which now accounts for
                the majority of searches, videos can have an advantage over
                text-only results; depending on the query, the answer may be
                easier to watch than to read on a smartphone, and the search
                engines know that.
              </p>
              <p>
                Besides,{" "}
                <a
                  href="https://www.youtube.com/watch?v=O9s_C1y3mRo"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a>{" "}
                is the No. 2 search engine, ahead of Bing, and is often the
                GO-TO site for “how to do …” queries.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/bruce-clay.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Bruce Clay"
                  className="rounded-circle"
                />
                <p className="expert-name">Bruce Clay</p>
                <p className="info">
                  Internet Marketing Expert and Founder at
                  <a
                    href="https://www.bruceclay.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bruce Clay, Inc
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="shayla-price">
              <p>
                Explainer videos are a must-have for businesses to communicate
                their brand messages. Whether it's a complex idea or a creative
                solution, explainer videos leave a lasting impression on
                customers to learn more about your products and services.
              </p>
              <p>
                Video marketing is essential for grabbing the attention of your
                audience. When done effectively, adding video to your marketing
                campaigns builds an emotional connection with your customers,
                which can lead to brand awareness and sales.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/shayla-price.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Shayla Price"
                  className="rounded-circle"
                />
                <p className="expert-name">Shayla Price</p>
                <p className="info">B2B Content Marketer</p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvWhyVideoMarketing
