import React from "react"
import { Link } from "gatsby"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvFooter = () => {
  return (
    <section className="ev-footer">
      <Container>
        <Row>
            <Col lg={12} className="text-center">
                <p>If you found this guide on explainer video useful, do us a favor by sharing it:</p>
                <div className="social-share text-center py-4">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>                
                <p>Think it’s time to get yourself an explainer video? Take a look at the <Link to="/explainer-video/faq/">Frequently Asked Questions!</Link></p>
            </Col>            
        </Row>
      </Container>
    </section>
  )
}

export default EvFooter
