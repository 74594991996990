import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import VideoModal from "./videoModal"
import TwitterIcon from "../icon/twitterIcon"
import FacebookIcon from "../icon/facebookIcon"
import "../../sass/global/modal-video.scss"

const EvTypes = () => {
  const data = useStaticQuery(graphql` 
  {
    allMarkdownRemark(
      sort: {fields: frontmatter___thumbnail___birthtime}
      filter: {frontmatter: {page: {eq: "animated-explainer"}}}
    ) {
      nodes {
        frontmatter {
          title
          videoid
          channel
          thumbnail {
            childImageSharp {
              gatsbyImageData(formats: AUTO, width: 350, height: 250, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }    
  `)
  
  const evtypes = data.allMarkdownRemark.nodes
  return (
    <section className="ev-types">
      <Container>
        <h2 className="text-center" id="types">
          Types Of Explainer Videos
        </h2>
        <Row className="justify-content-center wrapper-main">
          <Col lg={11}>
            <Row>
              {evtypes.map((evtype) => (
                  <Col md={4} sm={6} xs={6}>
                    <VideoModal type={evtype} />
                  </Col>
              ))}
            </Row>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="sam-hurley">
              <p>
                People still love to read – but video is only getting bigger due
                to its personal feel and of course, the fact visual content
                requires little effort to digest.
              </p>
              <p>
                Dr. James McQuivey estimates that one minute of video is equal
                to 1.8 million words!
              </p>
              <p>
                That's not all: 50% of company execs seek more information after
                noticing a product or service in video content.
              </p>
              <p>
                In addition, a study showed that using video on landing pages
                can increase conversion rates by a whopping 80%!
              </p>
              <p>
                It’s time to adapt to trends if you haven't already. Begin
                creating a mixture of live and recorded video for your audiences
                in 2017.
              </p>
              <p>
                Besides,{" "}
                <a
                  href="https://www.youtube.com/watch?v=O9s_C1y3mRo"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a>{" "}
                is the No. 2 search engine, ahead of Bing, and is often the
                GO-TO site for “how to do …” queries.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/sam-hurley.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Bruce Clay"
                  className="rounded-circle" />
                <p className="expert-name">Sam Hurley</p>
                <p className="info">
                  Founder of
                  <a
                    href="https://optim-eyez.co.uk/coming-soon/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    OPTIM-EYEZ
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                 <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="moosa-hemani">
              <p>
                As far as the explainer videos are concern, according to{" "}
                <a
                  href="http://unbounce.com/conversion-rate-optimization/explainer-videos-increase-conversions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Unbounce
                </a>
                , “Explainer Videos have the tendency to increase the conversion
                rate by 20%”. But, if you ask me about the top 2 reasons why you
                should go for explainer videos will be:
              </p>
              <p>
                There are two kinds of people, one that goes with verbal
                learning and others that go with visual learning. Explainer
                videos have both powers, which allow more people to engage with
                it and thus, have more power to convert visitors into leads.
              </p>
              <p>
                More chances of being shared. Explainer videos have more power
                to engage audience thus, chances of being shared on social media
                is higher than usual and you will be able to reach out to wider
                audience as a business.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/moosa-hemani.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Moosa Hemani"
                  className="rounded-circle" />
                <p className="expert-name">Moosa Hemani</p>
                <p className="info">
                  {" "}
                  CEO & Founder of
                  <a
                    href="http://www.setalks.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SETalks
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvTypes
