import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvTipsDetails = () => {
  return (
    <section className="ev-tips-details">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <ol className="num-round color-5">
              <li>
                <h3>A Crystal Clear Scrypt.</h3>
                <p>
                  The whole point of having an explainer video is to explain
                  things in the most effective way. This is why the script
                  should be simple and very easy to understand. Avoid marketing
                  jargon at all costs. If the viewer is confused then there is a
                  very high chance he will stop the video because there are
                  better things to watch right at the click of a button.
                </p>

                <p>
                  Sentences are attention currencies. A good sentence can buy
                  you the first 10 seconds of your viewers attention, which
                  should buy you the next 10 seconds.
                </p>
              </li>
              <li>
                <h3>The Shorter The Better.</h3>
                <p>
                  The average attention span for a human being is 8 seconds.
                  That’s one less second than that of a
                  goldfish. So anywhere in those 8 seconds, if the viewer
                  doesn't feel compelled to watch it you will lose his
                  attention.
                </p>
                <p>
                  This is why it should be short. How long should explainer
                  videos be? Typically just under 3 minutes.
                </p>
                <p>Is 3 minutes enough to explain all your concepts?</p>
                <p>
                  According to Forrester Research a minute of video is worth 1.8
                  Million words. Putting this into perspective, a one minute
                  video has the same influential power as 900{" "}
                  <a href="#blog">blog</a> posts of 2000 words each.
                </p>
              </li>
              <li>
                <h3>Put Benefits Over Features.</h3>
                <p>
                  It can be tempting to talk about all the cool features of your
                  product or service in your explainer videos.
                </p>
                <p>
                  But the important thing to keep in mind is that people don’t
                  care about the features. They care about how exactly these
                  features will benefit them.
                </p>
                <p>
                  Think about Apple's marketing tactic. Rather than say that
                  iPods have 8GB of memory they told the people exactly how many
                  songs or videos you can store with that kind of memory. The
                  latter is what customers want to know.
                </p>
              </li>
              <li>
                <h3>Call People & Trigger Their Action.</h3>
                <p>
                  Good explainer videos have an explicit call to action at the
                  end of the video. This could be anything from purchasing a
                  product to simply moving on to another webpage of your
                  website.
                </p>
                <p>
                  People are inspired to take action through emotion and not
                  facts.Include the pain points of the potential customers in
                  your explainer video so that they can relate to it and later
                  take action. It's always better to remind someone of their
                  problems before providing a solution.
                </p>
              </li>
            </ol>
          </Col>
          <Col lg={12}>
            <p>
              These are just some of the tips on how to produce explainer
              videos. The best learning is through observation and practice, so{" "}
              <a href="/videos">watch a lot of explainer videos</a> (especially
              videos of your competitors) and learn by doing.
            </p>
            <div className="expert-quote text-center" id="shanelle-mullin">
              <p>
                "As always, your decision-making process should start with
                conversion research. Start by identifying points of hesitation
                and uncertainty on your site. Then, identify points of
                complexity and vagueness. These are likely the points where your
                visitors would benefit most from video.
              </p>
              <p>
                Video is gaining a ton of steam and it's difficult to argue that
                it's not engaging. In fact, studies have found that online video
                accounts for 50% of all mobile traffic and that 40% of consumers
                say video increases the chance they’ll purchase a product on
                their mobile device.
              </p>
              <p>
                But a lot of things have been found to improve other people's
                conversion rates. It doesn't mean you should implement blindly.
                What matters is whether video will{" "}
                <a
                  href="https://conversionxl.com/optimize-videos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  improve your conversion
                </a>{" "}
                rate.
              </p>
              <p>
                Test it for yourself. If you don't have enough traffic to be A/B
                testing yet, use qualitative research to explore whether it's
                effective. User testing and session replays will help."
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/shanelle-mullin.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Shanelle Mullin"
                  className="rounded-circle"
                />
                <p className="expert-name">Shanelle Mullin</p>
                <p className="info">
                  Content & Growth at
                  <a
                    href="https://conversionxl.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ConversionXL
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvTipsDetails
