import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvProductionProcessDetails = () => {
  return (
    <section className="ev-production-process-details">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <ol className="num-round color-4">
              <li>
                <h4>Brainstorming & Concept Discussion</h4>
                <Row className="flex-lg-row-reverse">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/concept.jpeg"
                      width={450}
                      height={350}
                      alt="Brainstorming & Concept Discussion"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      In this stage, explainer video agencies take the time to
                      understand the client and their goals for the video.{" "}
                    </p>
                    <p>
                      Each video is unique, so there is a brainstorming session
                      where the creative directors and scriptwriters decide the
                      angle for the story the client wants to tell.{" "}
                    </p>
                    <p>
                      The type of explainer video is also decided according to
                      the goals and message of the client.
                    </p>
                  </Col>
                </Row>
              </li>
              <li>
                <h4>Scripting & Storyboard</h4>
                <Row className="flex-lg-row-reverse align-items-center">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/script.jpeg"
                      width={450}
                      height={350}
                      alt="Scripting & Storyboard"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      The success of an explainer video lies in good
                      storytelling. After deciding on the direction, a clear,
                      simple and compelling script is developed by the
                      scriptwriters.
                    </p>
                    <p>
                      After the concept and script are approved, a storyboard is
                      sketched out that shows how the script will play out –
                      scene by scene.
                    </p>
                    <p>
                      The storyboard will have 3 kinds of information – a series
                      of hand-drawn scenes, a description of each scene, and the
                      voice-over for each scene.
                    </p>
                  </Col>
                </Row>
              </li>
              <li>
                <h4>Style</h4>
                <Row className="flex-lg-row-reverse">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/style.jpeg"
                      width={450}
                      height={350}
                      alt="Style"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      Next comes the style. These custom, full-color images will
                      give you a glimpse of what your final video will look
                      like, and depending on your video, may include text,
                      characters, backgrounds, icons and more!
                    </p>
                  </Col>
                </Row>
              </li>
              <li>
                <h4>Voiceover</h4>
                <Row className="flex-lg-row-reverse">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/voiceover.jpeg"
                      width={450}
                      height={350}
                      alt="Voiceover"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      The wrong voice and tone can completely ruin the look and
                      feel of your video.
                    </p>
                    <p>
                      That’s why a selection of voice-over samples from
                      different artists are offered to the client to choose
                      from, along with suggestions of what will work best.{" "}
                    </p>
                    <p>
                      The approved voice over artist then narrates the script
                      and records the narration.
                    </p>
                  </Col>
                </Row>
              </li>
              <li>
                <h4>Animation</h4>
                <Row className="flex-lg-row-reverse">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/animation.jpeg"
                      width={450}
                      height={350}
                      alt="Animation"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      With the storyboard and style in place, and the narration
                      approved, the illustrations are then animated by using
                      softwares like Adobe After Effects, Photoshop,
                      Illustrator, Flash, and Autodesk 3DS Max (StudioMax) .{" "}
                    </p>
                    <p>
                      This is the longest stage of the process and is what makes
                      the video come to life.
                    </p>
                  </Col>
                </Row>
              </li>
              <li>
                <h4>Sound Design</h4>
                <Row className="flex-lg-row-reverse">
                  <Col lg={6}>
                    <StaticImage
                      src="../../images/explainer-video/sound-design.jpeg"
                      width={450}
                      height={350}
                      alt="Sound Design"
                      placeholder="blurred"
                    />
                  </Col>
                  <Col lg={6}>
                    <p>
                      You may not think that this is very important, but a good
                      soundtrack is essential for creating a highly converting
                      product video.{" "}
                    </p>
                    <p>
                      The soundtrack sets the mood for the video. It stimulates
                      the audio receptors within our brain and keeps us even
                      more engaged to the video.{" "}
                    </p>
                    <p>
                      The music is either purchased online or produced by the
                      inhouse production studio of the explainer video agency.
                    </p>
                  </Col>
                </Row>
              </li>
            </ol>
            <p className="text-center wrapper-main">
              Here's a cool illustration of our explainer video production
              process.
            </p>
            <StaticImage
              src="../../images/explainer-video/explainer-video-process.jpeg"
              width={1000}
              height={563}
              alt="WowMakers Explainer Video Process"
              placeholder="blurred"
              style={{ border: "1px solid #d5d5d5", marginTop: "15px" }}
            />
            <div className="expert-quote text-center" id="mordecai-holtz">
              <p className="text-left">
                Explainer videos are one of the hottest trends in marketing. It
                is a powerful tool with proven ROI pushing the needle forward
                for any business. The marketing potential is off the charts! A
                solid explainer video can boost sales, increase conversion
                rates, tell a strong business narrative, and bring life to your
                sales pitch.
              </p>
              <p className="text-left">
                <strong>What’s the recipe for a strong explainer video?</strong>
              </p>
              <p className="text-left">
                Script: The key to a great video is the script.It’s the base for
                the entire success of a video. The best scripts come from a team
                of writers who are not involved in the day to day life of the
                product. The script should reflect the DNA of the product but
                not oversell.
              </p>
              <p className="text-left">
                Length: Industry standard is 150 words per minute. The longer
                the video the higher drop off rate. Keep it short. Keep it
                Simple. The best explainer videos are laser focused on these 4
                topics. They also simplify the message.
              </p>
              <ol className="text-left" style={{ listStylePosition: "inside" }}>
                <li>The problem – Address the pain (0:00-0:20)</li>
                <li>
                  The solution – Introduce the product or service as the
                  solution (0:20-0:25)
                </li>
                <li>
                  How it works – How to get started with the solution
                  (0:25-0:50)
                </li>
                <li>
                  A call to action – critical and keep it focused on 1 CTA
                  (0:50-0:60)
                </li>
                <li>
                  Sizzle not the Steak: Remember sell the benefits of a product
                  not the features.
                </li>
                <li>
                  Think Like a User: Explainer videos resonate with the audience
                  by thinking from the user perspective. Entertain don’t
                  explain. Engage them don’t teach them.
                </li>
              </ol>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/mordecai-holtz.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Mordecai Holtz"
                  className="rounded-circle"
                />
                <p className="expert-name">Mordecai Holtz</p>
                <p className="info">
                  Founder,
                  <a
                    href="http://www.bluethreadmarketing.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blue Thread Marketing
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                 <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
            <div className="expert-quote text-center" id="tim-ryan">
              <p>
                Explainer videos get prospects to look at your products or
                services in a unique manner. They quickly identify a problem and
                provide a better solution. Creativitiy and storytelling always
                help engagement and conversion. Housecall, a startup company,
                used our explainer video to help them raise more than $3M in
                funding and gain thousands of new users.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/tim-ryan.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Tim Ryan"
                  className="rounded-circle"
                />
                <p className="expert-name">Tim Ryan</p>
                <p className="info">Founder & Director at TAR Productions</p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvProductionProcessDetails
