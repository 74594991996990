import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvWhatIs = () => {
  return (
    <section className="what-is-ev">
      <Container>
        <h2 className="text-center" id="definition">
          What’s An Explainer Video?
        </h2>
        <Row className="flex-lg-row-reverse">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/explainer-video-definition.png"
              width={450}
              height={350}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p>
              An explainer video is defined as a short video that businesses use
              to quickly introduce themselves, explain what they do, and tell
              how they can help solve their customer's problems.
            </p>
            <p>Explainer videos can be used to:</p>
            <ol>
              <li>Explain the details of a new product.</li>
              <li>Explain the services of your business.</li>
              <li>Communicate an idea or message to your customers.</li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvWhatIs
