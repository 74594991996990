import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvProductionProcess = () => {
  return (
    <section className="ev-production-process">
      <Container>
        <h2 className="text-center" id="process">
          The Production Process To Make Explainer Videos?
        </h2>
        <Row className="flex-lg-row-reverse align-items-center">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/how-to-make-explainer-videos.png"
              width={450}
              height={240}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p className="header-description">
              So what really goes on behind the scenes when creating an animated
              video. A lot actually!
            </p>
            <p className="header-description">
              How do explainer video companies work their magic?
            </p>
            <p className="header-description">
              {" "}
              There are many creative minds like scriptwriters, animators and
              voice over artist behind the process.
            </p>
            <p className="header-description" style={{marginBottom: "0"}}>
              Let’s take a better look at{" "}
              <a href="#tips">how to make explainer videos</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvProductionProcess
