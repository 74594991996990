import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const EvToolsWhiteBoard = () => {
  return (
    <section className="ev-tools white-board">
      <Container>
        <h3 className="text-center">
            Tools For Creating Whiteboard Explainer videos
        </h3>
        <Row>
            <Col lg={6}>
                <h3>
                    <span className="item-number">1</span>
                    <Link to="/blog/explainer-video-software/#RawShorts" title="PowToon" target={"_blank"} className="item-content">RawShorts</Link>
                    <p className="mt-4">What you’ll love about this software is their creative graphics templates across a lot of categories.</p>                    
                </h3>
            </Col>
            <Col lg={6}>
                <h3>
                    <span className="item-number">2</span>
                    <Link to="/blog/explainer-video-software/#VideoScribe" title="Animaker" className="item-content" target={"_blank"}>VideoScribe</Link>
                    <p className="mt-4">VideoScribe is great as it lets you edit and make animated whiteboard videos using royalty-free images and music (over 1k of them).</p>                    
                </h3>
            </Col>            
        </Row>
      </Container>
    </section>
  )
}

export default EvToolsWhiteBoard
