import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvVideoMarketing = () => {
  return (
    <section className="video-marketing">
      <Container>
        <Row>
          <h3 className="col-lg-12 text-center" id="video-marketing">
            A Sneak Peak Into Video Marketing
          </h3>
          <Col lg={5} className="wrapper-main offset-lg-1">
            <p>
              Online video marketing is a strategy in content marketing that
              make use of videos to effectively communicate with your audience
              about your brand, product or service.
            </p>
            <p>
              Videos are being used in different stages of the{" "}
              <a
                href="/blog/explainer-video-use-cases/"
                target="_blank"
                rel="tag"
              >
                buyer’s journey
              </a>
              for all kinds of purposes like about us videos, how-to videos,
              webinars, explainer videos, corporate training videos and even
              <a href="/blog/client-testimonial-video-tips/" target="_blank">
                customer testimonials
              </a>
              .
            </p>
          </Col>
          <Col lg={6} className="wrapper-main">
            <StaticImage
              src="../../images/explainer-video/video-marketing.png"
              width={450}
              height={350}
              alt="Video Marketing Wowmakers"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvVideoMarketing
