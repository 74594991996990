import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 48 48"
      fill="#fff"
    >
      <path d="M32 6c-5.431 0-9.84 4.389-9.957 9.793-4.802-.576-7.93-2.371-9.848-4.14-2.127-1.963-2.8-3.706-2.8-3.706A1.5 1.5 0 006.8 7.6S5 10 5 13.5c0 2.001.632 3.482 1.385 4.736-.028-.013-.215-.078-.215-.078a1.5 1.5 0 00-2.164 1.479s.577 4.22 4.545 7.304l-.414.104a1.5 1.5 0 00-.94 2.2s.673 1.137 2.082 2.335c.836.71 2.063 1.443 3.51 2.121C11.012 34.351 8.363 35 4.5 35a1.5 1.5 0 00-1.129 2.488s1.146 1.263 3.412 2.362C9.05 40.948 12.54 42 17.5 42c8.72 0 14.985-4.183 18.895-9.484C40.305 27.214 42 20.86 42 16c0-.308-.02-.613-.047-.914 2.111-2.034 2.904-3.564 2.942-3.639a1 1 0 00-1.313-1.355l-.162.074-.418.191a8.797 8.797 0 00.951-2.06 1 1 0 00-1.473-1.152c-1.222.742-2.393 1.262-3.613 1.61A9.954 9.954 0 0032 6zm0 3c3.883 0 7 3.117 7 7 0 4.139-1.554 10.036-5.02 14.734C30.515 35.433 25.28 39 17.5 39c-3.765 0-6.27-.681-8.146-1.418 1.965-.305 3.658-.713 4.875-1.184 2.11-.816 3.248-1.76 3.248-1.76a1.5 1.5 0 00-.614-2.593c-2.722-.68-4.469-1.782-5.564-2.7l1.564-.39a1.5 1.5 0 00.176-2.855c-3.245-1.249-4.608-3.014-5.305-4.493.848.176 1.638.393 2.766.393a1.5 1.5 0 00.67-2.842S8 17.7 8 13.5c0-.754.209-1.232.361-1.803.524.703.955 1.382 1.8 2.16 2.574 2.375 6.83 4.796 13.259 5.141A1.5 1.5 0 0025 17.5V16c0-3.883 3.117-7 7-7z"></path>
    </svg>
  );
}

export default TwitterIcon;