import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvBenefits = () => {
  return (
    <section className="ev-benefits">
      <Container>
        <h2 className="text-center" id="benefits">Why Explainer Videos Work?</h2>
        <Row className="flex-lg-row-reverse align-items-center">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/benefits.png"
              width={450}
              height={350}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p className="header-description">
              So, are you still wondering whether you need one? Not sure how
              effective these videos are or how it can help your business grow?
            </p>
            <p className="header-description">
              In that case, let’s more talk about the 
              <strong>benefits of explainer videos</strong> and why should have
              one.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvBenefits
