import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvContent = () => {
  return (
    <section className="ev-content">
      <Container>
        <Row>
          <h4 className="col-lg-12 text-center">Table of Content</h4>
        </Row>
        <Row className="justify-content-lg-start justify-content-center wrapper-main">
          <Col lg={2} xs={4} className="offset-lg-1">
            <Link
              to="#video-marketing"
              title="Video Marketing"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Videomarketing.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Video Marketing"
                className="rounded-circle"
              />
              <p className="expert-name">Video Marketing</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#definition"
              title="What Is It?"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Explainervideo.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="What Is It?"
                className="rounded-circle"
              />
              <p className="expert-name">What Is It?</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#types"
              title="Types Of Explainer Videos"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Typesofexplainervideo.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Types Of Explainer Videos"
                className="rounded-circle"
              />
              <p className="expert-name">Types Of Explainer Videos</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#benefits"
              title="Benefits"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Whyitworks.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Benefits"
                className="rounded-circle"
              />
              <p className="expert-name">Benefits</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#process"
              title="Production Process"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Production-process.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Production Process"
                className="rounded-circle"
              />
              <p className="expert-name">Production Process</p>
            </Link>
          </Col>
          <Col lg={2} xs={4} className="offset-lg-1">
            <Link
              to="#uses"
              title="Ways To Use"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Ways.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Ways To Use"
                className="rounded-circle"
              />
              <p className="expert-name">Ways To Use</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#tips"
              title="Explainer Video Tips"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Whatmakes.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Explainer Video Tips"
                className="rounded-circle"
              />
              <p className="expert-name">Explainer Video Tips</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#cost"
              title="Cost"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Cost.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Cost"
                className="rounded-circle"
              />
              <p className="expert-name">Cost</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#diy"
              title="DIY Explainer Videos?"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/DIY.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="DIY Explainer Videos?"
                className="rounded-circle"
              />
              <p className="expert-name">DIY Explainer Videos?</p>
            </Link>
          </Col>
          <Col lg={2} xs={4}>
            <Link
              to="#infographic"
              title="Infographic"
              className="experts-link text-center"
            >
              <StaticImage
                src="../../images/explainer-video/Tips.jpeg"
                placeholder="blurred"
                width={100}
                height={100}
                alt="Infographic"
                className="rounded-circle"
              />
              <p className="expert-name">Infographic</p>
            </Link>
          </Col>                                                  
        </Row>
      </Container>
    </section>
  )
}

export default EvContent
