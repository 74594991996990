import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvHeader = () => {
  return (
    <section className="ev-header">
      <Container>
        <Row className="content justify-content-center">
          <Col lg={12}>
            <div className="title text-center">
              <h1 className="entry-title">
                <span>One stop guide to</span>
                Explainer Video
              </h1>
            </div>
          </Col>
        </Row>
        <Row className="flex-lg-row-reverse align-items-center text-center text-lg-left">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/explainer-video-guide.png"
              alt=""
              width={450}
              height={350}
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p className="header-description">
              What are the benefits of explainer videos, how it works & why you
              need one? Here's the best guide to its production process &
              explainer video software.
            </p>
            <p className="header-description" style={{marginBottom: '0'}}>
              We’ve covered almost{" "}
              <strong>everything about explainer videos</strong> in this single
              guide with the help of top experts in the industry and our team at
              WowMakers.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvHeader
