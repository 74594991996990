import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvWhatIsDetail = () => {
  return (
    <section className="ev-what-is-details">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="expert-quote text-center" id="jeff-bullas">
              <p>
                The social and mobile web thrives on visual content. Video is
                highly engaging when you use text, cartoon like caricatures with
                some audio. Making the complex simple in one to two minutes for
                a world that is overwhelmed with content is also a breath of
                fresh air. So to sum it up..... Explainer videos are like
                infographics on steroids and who wouldn't enjoy that!
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/jeff-bullas.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Jeff Bullas"
                  className="rounded-circle"
                />
                <p className="expert-name">Jeff Bullas</p>
                <p className="info">Marketing Strategist</p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="aldrich-obach">
              <p>
                Explainer videos are a great opportunity for any business to
                introduce or explain their products/services within context. A
                product page on its own can certainly get your message across
                but using video will elevate your message to include an
                emotional element which is one the foundations of sales &
                marketing. 'More video content is uploaded in the last 30 days
                than all 3 major US TV networks combined have created in 30
                years!' If you're not investing in video content, you're lagging
                behind and soon left behind. When it comes to positioning your
                product and or services, nothing is more efficient than
                producing quality videos.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/aldrich-obach.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Aldrich Obach"
                  className="rounded-circle"
                />
                <p className="expert-name">Aldrich Obach</p>
                <p className="info">
                  International Account Executive, &nbsp;
                  <a
                    href="https://sproutsocial.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sprout Social
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={12} className="wrapper-main">
            <p className="font-weight-bold">
              The explainer video statistics by{" "}
              <a
                href="https://www.wyzowl.com/video-marketing-statistics-2016.html"
                target="_blank"
                rel="noreferrer"
              >
                Wyzowl
              </a>{" "}
              is just astonishing.
            </p>
            <div className="note">
              <ul>
                <li>
                  <strong>93%</strong> of businesses that use video believe that
                  it has increased user understanding of their product or
                  service.
                </li>
                <li>
                  <strong>36%</strong> of businesses believe that they've
                  received less support queries as a result!
                </li>
                <li>
                  <strong>45%</strong> of businesses who use video say they have
                  an explainer video on their <a href="#home-page">home page</a>
                  .
                </li>
                <li>
                  <strong>83%</strong> say their homepage has become more
                  effective
                </li>
              </ul>
            </div>
            <p>
              The best part is that businesses are open to being a lot more
              creative in their video marketing strategy. This is why there are
              all kinds of formats like{" "}
              <a
                href="/live-video-production/#live-action"
                rel="tag"
                target="_blank"
              >
                live action
              </a>
              ,{" "}
              <a
                href="/whiteboard-video-production/"
                target="_blank"
                title="Check out our whiteboard video production service"
                rel="tag"
              >
                whiteboard animation
              </a>{" "}
              and 2D animations. Animations have the power to engage the
              audience through great visual experiences and creative
              storytelling. So it’s no surprise that animated videos are one of
              the most popular among the different{" "}
              <a href="#types">types of explainer videos.</a>
            </p>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="kimbe-macmaster">
              <p>
                Explainer videos are often the best option for ... well,
                explaining complex concepts! With both audio and visual
                stimulation, explainer videos make it easier to understand
                things like the problem your product solves or the way your
                service works in a short period of time. And since no one has
                time for lengthy content, great explanations in short bursts of
                time are the perfect match.
              </p>
              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/kimbe-macmaster.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Kimbe MacMaster"
                  className="rounded-circle"
                />
                <p className="expert-name">Kimbe MacMaster</p>
                <p className="info">
                  Content Marketing Manager at &nbsp;
                  <a
                    href="https://www.vidyard.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Vidyard
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="expert-quote text-center" id="margot-mazur">
              <p>
                Explainer videos allow folks to see your product, quickly grasp
                what your company does, and relate to your company through the
                video that you create.
              </p>
              <p>
                Video creates a sense of trust and relatability that the best
                copy can't give you. Investing in video marketing is investing
                in relationship building with your prospects and customers.
              </p>

              <div className="expert-details">
                <StaticImage
                  src="../../images/explainer-video/margot-mazur.jpeg"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  alt="Margot Mazur"
                  className="rounded-circle"
                />
                <p className="expert-name">Margot Mazur</p>
                <p className="info">
                  Partnership Co-ordinator at &nbsp;
                  <a
                    href="https://wistia.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wistia
                  </a>
                </p>
              </div>
              <div className="social-share text-center">
                <a
                  href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                  target="_blank"
                  className="tweet"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                  <span>Tweet</span>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                  rel="noreferrer"
                  className="fb-share"
                >
                  <FacebookIcon />
                  <span>Share</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvWhatIsDetail
