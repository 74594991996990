import React from "react"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Container, Row, Col } from "react-bootstrap"

const EvBriefVideo = () => {
  return (
    <section className="ev-brief-video">
      <Container>
        <Row className="justify-content-center">
          <h4 className="col-lg-12 text-center">
            Watch This Short Video About Explainer Videos
          </h4>
          <Col lg={10} className="wrapper-main">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/O9s_C1y3mRo"
              frameborder="0"
              allowfullscreen
              title="wowmakers explainer video guide"
            ></iframe>
            <div className="social-share wrapper-main text-center">
              <a href="https://twitter.com/intent/tweet?text=https://www.youtube.com/watch?v=O9s_C1y3mRo" target="_blank" className="tweet" rel="noreferrer">
                <TwitterIcon />
                <span>Tweet</span>
              </a>
              <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://www.youtube.com/watch?v=O9s_C1y3mRo" rel="noreferrer" className="fb-share">
                <FacebookIcon />
                <span>Share</span>
              </a>              
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvBriefVideo
