import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookIcon from "../icon/facebookIcon"
import TwitterIcon from "../icon/twitterIcon"
import { Col, Container, Row } from "react-bootstrap"

const EvWhereToPlaceDetails = () => {
  return (
    <section className="ev-where-to-place-details">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <ol className="num-round color-6">
              <li>
                <h4 id="home-page">On Your Home Page</h4>
                <p>
                  This is one of the most common uses of an animated marketing
                  video. This is a great way to showcase what your company does
                  and your work culture.{" "}
                </p>
                <p>
                  Make sure that you place this video above the fold of your
                  home page so that your viewers can’t miss it.
                </p>
                <Row>
                  <Col lg={6}>
                    <a
                      href="https://www.crazyegg.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/crazyegg-homepage.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt=""
                      />
                      <p className="text-center">
                        CrazyEgg is using their product explainer video on the
                        homepage.
                      </p>
                    </a>
                  </Col>
                  <Col lg={6}>
                    <a
                      href="https://www.pipedrive.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/pipedrive-crm.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt=""
                      />
                      <p className="text-center">
                        Pipedrive uses an explainer video to walk users through.
                      </p>
                    </a>
                  </Col>
                  <Col lg={12}>
                    <div className="expert-quote text-center" id="sujan-patel">
                      <p>
                        Explainer videos prominently featured on the homepage
                        can increase conversions significantly. You never want
                        to overload a visitor with copy, but it's hard to
                        distill all of your features and benefits to images and
                        quick blurbs of text.
                      </p>

                      <p>
                        Explainer videos allow you take the 300 words you can't
                        find room for and cut it down to a 30 second video
                        that's visual, helpful, and conversion-focused. We do it
                        on our homepage at{" "}
                        <a
                          href="https://mailshake.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mailshake
                        </a>{" "}
                        and often get compliments from new customers saying how
                        helpful it was with their decision to buy the product.
                      </p>
                      <div className="expert-details">
                        <StaticImage
                          src="../../images/explainer-video/sujan-patel.jpeg"
                          placeholder="blurred"
                          width={100}
                          height={100}
                          alt="Sujan Patel"
                          className="rounded-circle"
                        />
                        <p className="expert-name">Sujan Patel</p>
                        <p className="info">
                          Growth Marketer & Co-Founder at
                          <a
                            href="https://webprofits.agency/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Web Profits
                          </a>
                        </p>
                      </div>
                      <div className="social-share text-center">
                        <a
                          href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                          target="_blank"
                          className="tweet"
                          rel="noreferrer"
                        >
                          <TwitterIcon />
                          <span>Tweet</span>
                        </a>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                          rel="noreferrer"
                          className="fb-share"
                        >
                          <FacebookIcon />
                          <span>Share</span>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </li>
              <li>
                <h4 id="landing-page">On Your Landing Page</h4>
                <p>
                  It’s a really good idea to have a video on your landing pages.
                  The video should match the purpose of the landing page. So now
                  you are giving your viewers the option of watching a video
                  instead of reading the text in your web page.{" "}
                </p>
                <p>
                  Having a video on your landing page reduces bounce rate and
                  increases the average visit time by 2 minutes. Make sure to
                  have a CTA visible while watching the video or immediately at
                  the end of the video.
                </p>
                <Row>
                  <Col lg={6}>
                    <a
                      href="https://www.insightly.com/features/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/insightly-homepage.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="Insightly uses an explainer video to explain their features."
                      />
                      <p className="text-center">
                        Insightly uses an explainer video to explain their
                        features.
                      </p>
                    </a>
                  </Col>
                  <Col lg={6}>
                    <a
                      href="https://www.pipedrive.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/salesforce.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="Salesforce is also doing a great job."
                      />
                      <p className="text-center">
                        Salesforce is also doing a great job.
                      </p>
                    </a>
                  </Col>
                </Row>
              </li>
              <li>
                <h4 id="email">Videos In Your Emails</h4>
                <p>
                  Emails are still one of the best communication mediums to date
                  and probably the biggest driver of conversions and lead
                  generation.
                </p>

                <p>
                  Emails can be made even more effective by adding videos to
                  them:
                </p>

                <ul className="wrapper-main">
                  <li>
                    Videos make your emails stand out. They are much more
                    engaging than a textual copy and your message will be
                    communicated to your target audience with maximum clarity.
                  </li>

                  <li>
                    Research shows that video emails received better response in
                    terms of open rates and conversion rates as compared to
                    traditional emails.
                  </li>
                  <li>
                    According to{" "}
                    <a
                      href="https://vivipins.com/video-marketing-statistics/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vivipins
                    </a>
                    , just including the word video on your email subject line
                    can:
                    <ol style={{ marginTop: "24px" }}>
                      <li>Boost open rates by 19%.</li>
                      <li>Boost click-through rates by 65%.</li>
                      <li>Reduce unsubscribes by 26%.</li>
                    </ol>
                  </li>
                </ul>

                <p>
                  The videos could be product demos, newsletters, customer
                  testimonials, training course videos or even a simple thank
                  you video to your customer.
                </p>

                <p>
                  Videos make videos more human and so it makes your message
                  more personal and trust worthy.
                </p>
                <Row>
                  <Col lg={6}>
                    <div className="why-ev-link">
                      <StaticImage
                        src="../../images/explainer-video/drift-email.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="Drift’s Email with video"
                      />
                      <p className="text-center">
                        An email from the Drift team.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="why-ev-link">
                      <StaticImage
                        src="../../images/explainer-video/wistia-email.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="Wistia’s Email with video"
                      />
                      <p className="text-center">
                        Wistia’s Email with a video in it.
                      </p>
                    </div>
                  </Col>
                </Row>
              </li>
              <li>
                <h4 id="blog">On Your Blog</h4>
                <p>
                  Blogs with heavy content can be quite difficult to digest.
                  This is why it’s becoming a trend for bloggers to add videos
                  to blog posts. Readers get a chance to take a break from
                  reading and can easily watch videos in the middle of the
                  content.
                </p>
                <p>
                  Search Engines are trying to provide better & valuable content
                  to users. And since videos can do the job better, it helps you
                  to increase your SERPs.
                </p>
                <Row>
                  <Col lg={6}>
                    <a
                      href="https://coschedule.com/blog/how-to-learn-new-skills-as-a-content-marketer/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/coschedule-blog-post.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="CoSchedule’s Blog Post"
                      />
                      <p className="text-center">
                        CoSchedule’s blog post is a great example.
                      </p>
                    </a>
                  </Col>
                  <Col lg={6}>
                    <a
                      href="https://webprofits.agency/blog/build-a-growth-team/"
                      target="_blank"
                      rel="noreferrer"
                      className="why-ev-link"
                    >
                      <StaticImage
                        src="../../images/explainer-video/webprofits-blog-post.png"
                        width={450}
                        height={350}
                        placeholder="blurred"
                        alt="Webprofits Blog Post"
                      />
                      <p className="text-center">
                        Check out this blog post by Webprofits
                      </p>
                    </a>
                  </Col>
                </Row>
              </li>
              <li>
                <h4 id="social-media">On Your Social Media Accounts</h4>
                <p>
                  Facebook now serves a whopping 8 billion video views every day
                  – that’s double the amount users were consuming in early 2015.
                </p>
                <p>
                  The greatest asset of explainer video marketing is its
                  shareability.
                </p>
                <p>
                  Because videos are so powerful in terms of engagement and
                  shareability, social media platforms today have been optimised
                  to include a lot more videos.
                </p>
                <p>
                  Today Facebook videos have a 10x higher viral reach than
                  YouTube links.
                </p>
                <p>
                  Instagram is a great platform for video marketing because they
                  were created solely for pictures and video. This makes it a
                  great place to launch a video to promote your brand, product
                  or service.
                </p>
                <p>
                  But it doesn’t stop here. Twitter, Vine and Vimeo all have one
                  thing to say- We want more videos!
                </p>
                <p>
                  Explainer videos can also be used as a presentation, for
                  conferences and even for startups to pitch new investors. The
                  possibilities for videos are limitless. Just remember that
                  anything that requires an explanation can be done using them.
                </p>
                <Row>
                  <Col lg={6}>
                    <blockquote class="twitter-tweet" data-lang="en">
                      <p lang="en" dir="ltr">
                        There are an incredible amount of social media tools on
                        the market! Which ones work? Our top 5 must-try social
                        tools of 2017 ⚒{" "}
                        <a href="https://t.co/PyluAPJUya">
                          pic.twitter.com/PyluAPJUya
                        </a>
                      </p>
                      &mdash; Buffer (@buffer){" "}
                      <a href="https://twitter.com/buffer/status/834855880931819523">
                        February 23, 2017
                      </a>
                    </blockquote>
                    <script
                      async
                      src="//platform.twitter.com/widgets.js"
                      charset="utf-8"
                    ></script>
                    <p class="textwrapp text-center">
                      See how Buffer is using videos in their Twitter?
                    </p>
                  </Col>
                  <Col lg={6}>
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FWowMakers%2Fvideos%2F1190412101007050%2F&show_text=1&width=560"
                      width="100%"
                      height="508"
                      style={{ border: "none", overflow: "hidden" }}
                      scrolling="no"
                      frameborder="0"
                      allowTransparency="true"
                      title="Locus 2D animation"
                    ></iframe>
                  </Col>
                  <Col lg={12}>
                    <div
                      className="expert-quote text-center"
                      id="johnathan-dane"
                    >
                      <p>
                        Video advertising is something your competitors are
                        sleeping on. Because of YouTube and Facebook, video
                        unlocks a brand new channel of conversion volume that
                        can't be tapped otherwise. In addition to that,
                        explainer videos are the most powerful way to generate
                        conversions for products/solutions/services that don't
                        have a lot of demand already.
                      </p>
                      <p>
                        They can educate and explain complex topics in
                        delightful ways that not only bring sales, but can help
                        build your brand equity over time too.
                      </p>
                      <div className="expert-details">
                        <StaticImage
                          src="../../images/explainer-video/johnathan-dane.jpeg"
                          placeholder="blurred"
                          width={100}
                          height={100}
                          alt="Johnathan Dane"
                          className="rounded-circle"
                        />
                        <p className="expert-name">Johnathan Dane</p>
                        <p className="info">
                          Founder & CEO at
                          <a
                            href="https://klientboost.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            KlientBoost
                          </a>
                        </p>
                      </div>
                      <div className="social-share text-center">
                        <a
                          href="https://twitter.com/intent/tweet?text=https://www.wowmakers.com/explainer-video"
                          target="_blank"
                          className="tweet"
                          rel="noreferrer"
                        >
                          <TwitterIcon />
                          <span>Tweet</span>
                        </a>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/sharer/sharer.php?u=https://www.wowmakers.com/explainer-video"
                          rel="noreferrer"
                          className="fb-share"
                        >
                          <FacebookIcon />
                          <span>Share</span>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvWhereToPlaceDetails
