import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const EvAuthors = () => {
  return (
    <section className="ev-authors">
      <Container>
        <Row>
          <h4 className="col-lg-12 text-center">ABOUT THE AUTHORS</h4>
          <Col lg={6}>
            <Row className="justify-content-center wrapper-main text-center text-lg-left">
              <Col lg={3}>
                <StaticImage
                  src="../../images/explainer-video/jaseem-thayal-shareef.jpeg"
                  width={150}
                  height={150}
                  placeholder="blurred"
                  alt=""
                  className="rounded-circle z-depth-2"
                />
              </Col>
              <Col lg={9}>
                <div className="author">
                  <span className="name bold">Jaseem Thayal Shareef</span>
                  <span className="designation">Founder & CEO</span>
                  <span className="info">
                    He's a video marketing enthusiast, soccer lover and always
                    curious to figure out how the world works. Beware, he can be
                    annoyingly happy all the time!
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row className="justify-content-center wrapper-main text-center text-lg-left">
              <Col lg={3}>
                <StaticImage
                  src="../../images/explainer-video/amal-rafeeq.jpeg"
                  width={150}
                  height={150}
                  placeholder="blurred"
                  alt=""
                  className="rounded-circle z-depth-2"
                />
              </Col>
              <Col lg={9}>
                <div className="author">
                  <span className="name bold">Amal Rafeeq</span>
                  <span className="designation">Digital Marketing Strategist</span>
                  <span className="info">
                    He's the digital marketing strategist at WowMakers. While
                    he's not doing experiments and optimizing the inbound
                    channels, you can find him solving different kinds of
                    Rubik's cubes.
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvAuthors
