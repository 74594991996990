import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import EvHeader from "../../components/explainer-video/evHeader"
import EvAuthors from "../../components/explainer-video/evAuthors"
import EvBriefVideo from "../../components/explainer-video/evBriefVideo"
import EvExperts from "../../components/explainer-video/evExperts"
import EvContent from "../../components/explainer-video/evContent"
import EvVideoMarketing from "../../components/explainer-video/evVideoMarketing"
import EvWhyVideoMarketing from "../../components/explainer-video/evWhyVideomarketing"
import EvWhatIs from "../../components/explainer-video/evWhatIs"
import EvWhatIsDetail from "../../components/explainer-video/evWhatIsDetail"
import EvTypes from "../../components/explainer-video/evTypes"
import EvBenefits from "../../components/explainer-video/evBenefits"
import EvBenefitsDetails from "../../components/explainer-video/evBenefitDetails"
import EvProductionProcess from "../../components/explainer-video/evProductionProcess"
import EvProductionProcessDetails from "../../components/explainer-video/evProductionProcessDetails"
import EvWhereToPlace from "../../components/explainer-video/evWhereToPlace"
import EvWhereToPlaceDetails from "../../components/explainer-video/evWhereToPlaceDetails"
import EvTips from "../../components/explainer-video/evTips"
import EvTipsDetails from "../../components/explainer-video/evTipsdetails"
import EvCost from "../../components/explainer-video/evCost"
import EvCostDetails from "../../components/explainer-video/evCostDetails"
import EvDiy from "../../components/explainer-video/evDiy"
import EvTools3d from "../../components/explainer-video/evTools3d"
import EvToolsWhiteBoard from "../../components/explainer-video/evToolsWhiteBoard"
import EvInfographic from "../../components/explainer-video/evInfographic"
import EvFooter from "../../components/explainer-video/evFooter"

import evFeraturedImage from "../../../static/explainer-video/explainer-video-image.png"
import logoImage from "../../../static/home/wowmakers.jpeg"

import "../../sass/pages/explainer-video.scss"

const siteUrl = "https://www.wowmakers.com"
const evSchemaImageUrl = siteUrl + evFeraturedImage
const logoImageUrl = siteUrl + logoImage
const evSchema = {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.wowmakers.com/explainer-video/"
  },
  "headline": "What's An Explainer Video, How & Why It Works Like Magic?",
  "description": "What are the benefits of explainer videos, how it works & why you need one? Here's the best guide to its production process & explainer video software.",
  "image": {evSchemaImageUrl},  
  "author": {
    "@type": "Person",
    "name": "Jaseem Thayal Shareef"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "WowMakers",
    "logo": {
      "@type": "ImageObject",
      "url": {logoImageUrl}
    }
  },
  "datePublished": "2017-03-30",
  "dateModified": "2022-12-23"
}
export const Head = () => (
  <>
    <SeoHead
      ogImage={evFeraturedImage}
      title="What's An Explainer Video, How & Why It Works Like Magic?"
      description="What are the benefits of explainer videos, how it works & why you need one? Here's the best guide to its production process & explainer video software."
    />
    <script type="application/ld+json">
      {JSON.stringify(evSchema)}
    </script>
  </> 
)

const ExplainerVideo = () => {
  return (
    <Layout>
      <Seo
        bclass="explainer-video"
        bid="explainer-video"
      />
      <ScrollIndicationHeightZero />
      <article id="explainer-video-guidelines" className="hentry">
        <div className="entry-content">
          <EvHeader />
          <EvAuthors />
          <EvBriefVideo />
          <EvExperts />
          <EvContent />
          <div className="article-body">
              <EvVideoMarketing />
              <EvWhyVideoMarketing />
              <EvWhatIs />
              <EvWhatIsDetail />
              <EvTypes />
              <EvBenefits />
              <EvBenefitsDetails />
              <EvProductionProcess />
              <EvProductionProcessDetails />
              <EvWhereToPlace />
              <EvWhereToPlaceDetails />
              <EvTips />
              <EvTipsDetails />
              <EvCost />
              <EvCostDetails />
              <EvDiy />
              <EvTools3d />
              <EvToolsWhiteBoard />
              <EvInfographic />
              <EvFooter />
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default ExplainerVideo
