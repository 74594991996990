import React,{useState} from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ModalVideo from 'react-modal-video'
import PlayIcon from "../icon/playIcon"
import "../../sass/global/modal-video.scss"

const VideoModal = ({type}) => {
    const [isOpen, setOpen] = useState(false)

	return (
        <React.Fragment>
			<ModalVideo channel={type.frontmatter.channel} autoplay isOpen={isOpen} videoId={type.frontmatter.videoid} onClose={() => setOpen(false)} />

                  <button className="videowrapp" onClick={() => setOpen(true)}>                 
                    <div className="video-box">
                      <GatsbyImage image={getImage(type.frontmatter.thumbnail)} alt={ type.frontmatter.title } />                      
                      <div className="play-overlay d-flex justify-content-center align-items-center">
                        <PlayIcon />
                      </div>
                    </div>
                    <p className="textwrapp text-center">{type.frontmatter.title}</p>                    
                  </button>
		</React.Fragment>
	)
}

export default VideoModal
