import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvCost = () => {
  return (
    <section className="ev-cost">
      <Container>
        <h2 className="text-center" id="cost">
          How Much Do Explainer Videos Cost?
        </h2>
        <Row className="flex-lg-row-reverse align-items-center">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/explainer-video-cost.png"
              width={450}
              height={350}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p>The million dollar question.</p>
            <p>
              Studies show an explainer video costs anywhere from $5000 up to
              $30,000.
            </p>
            <p>
              Pricing varies not only with length and style but also the quality
              of work, the complexity and the reputation of the production
              agency that you hire.
            </p>
            <p>Did it feel like you just got punched in the gut seeing these prices?</p>
            <p>It shouldn't.</p>
            <p>Let’s start with what these videos are not going to cost you.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvCost
