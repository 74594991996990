import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const EvTools3d = () => {
  return (
    <section className="ev-tools threed-video">
      <Container>
        <h3 className="text-center">
            Tools For Creating 2D Animated Explainer videos
        </h3>
        <Row>
            <Col lg={6}>
                <h3>
                    <span className="item-number">1</span>
                    <Link to="/blog/explainer-video-software/#PowToon" title="PowToon" target={"_blank"} className="item-content">PowToon</Link>
                    <p className="mt-4">PowToon is a free online software that lets you create cool animated videos.They have some really good ready-made templates and their “drag and drop” feature makes it very easy to use</p>                    
                </h3>
            </Col>
            <Col lg={6}>
                <h3>
                    <span className="item-number">2</span>
                    <Link to="/blog/explainer-video-software/#Animaker" title="Animaker" className="item-content" target={"_blank"}>Animaker</Link>
                    <p className="mt-4">With 5 different video styles and 120 different types of animations, Animaker lets you make really beautiful explainer videos for your product</p>                    
                </h3>
            </Col>            
        </Row>
      </Container>
    </section>
  )
}

export default EvTools3d
