import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvInfographic = () => {
  return (
    <section className="ev-infographic">
      <Container>
        <h2 className="text-center" id="infographic">
        Here’s An Infographic About Explainer Videos
        </h2>
        <Row>
            <Col lg={12} className="text-center">
            <StaticImage
              src="../../images/explainer-video/explainer-video-infographic.png"
              alt="Explainer Video Infographic"
              width={1000}
              height={11903}
              placeholder="blurred"
            />
            <div className="embed-infographic mt-5 pt-md-5 pt-0">
                <p><strong>Embed This Infographic On Your Site</strong> (copy code below):</p>
                <textarea readonly="readonly">{'<div class="clear-both"><a href="https://www.wowmakers.com/explainer-video/"><img src="https://www.wowmakers.com/explainer-video/explainer-video-infographic.png" alt="Explainer Video" title="Explainer Video Guide" class="img-responsive"/></a></div> <div> Infographics by: <a href="https://www.wowmakers.com/explainer-video/">WowMakers</a></div>'}</textarea>                
            </div>
            </Col>           
        </Row>
      </Container>
    </section>
  )
}

export default EvInfographic
