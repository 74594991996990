import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvTips = () => {
  return (
    <section className="ev-tips">
      <Container>
        <h2 className="text-center" id="tips">
          Tips To Make A Good Explainer Video
        </h2>
        <Row className="flex-lg-row-reverse align-items-center">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/explainer-video-tip.png"
              width={450}
              height={350}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p>
              Now that you heard all the great things about it, you might think
              that just having an explainer video is enough. Well sadly it
              doesn't work like that. There are a few things you need to
              consider to make the most out of these.
            </p>
            <p>Here are some of the best practices to make explainer videos.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvTips
