import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvDiy = () => {
  return (
    <section className="ev-diy">
      <Container>
        <h2 id="cost">
        DIY Explainer Videos?
        </h2>
        <Row className="flex-lg-row-reverse align-items-center">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/diy-explainer-videos.png"
              width={450}
              height={175}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={6}>
            <p>Is it possible to create an explainer video yourself?</p>
            <p>
                Well of course you can.
            </p>
          </Col>
          <Col lg={12}>
            <p>If you are looking for a live action explainer video, you might be considering the camera and equipment cost. There are many businesses that offer affordable equipment for rent if you don’t wish to purchase. Well if you really want to save up, you could just as easily record it on an iPhone.</p>
            <p>If you are looking for a 2D animation video or a whiteboard video, there are many different do it yourself <a href="https://www.wowmakers.com/blog/explainer-video-software/" target="_blank" rel="noreferrer">explainer video software</a> available.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvDiy
