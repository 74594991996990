import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvExperts = () => {
  return (
    <section className="ev-experts">
      <Container>
        <Row className="justify-content-center">
          <h4 className="col-lg-12 text-center">Contributing Experts</h4>
          <Col lg={10} className="wrapper-main">
            <Row>
              <Col lg={2} xs={4}>
                <Link to="#neil-patel" title="Neil Patel" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/neil-patel.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Neil Patel"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Neil Patel</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#bruce-clay" title="Bruce Clay" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/bruce-clay.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Bruce Clay"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Bruce Clay</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#shayla-price" title="Shayla Price" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/shayla-price.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Shayla Price"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Shayla Price</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#jeff-bullas" title="Jeff Bullas" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/jeff-bullas.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Jeff Bullas"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Jeff Bullas</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#aldrich-obach" title="Aldrich Obach" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/aldrich-obach.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Aldrich Obach"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Aldrich Obach</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#kimbe-macmaster" title="Kimbe MacMaster" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/kimbe-macmaster.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Kimbe MacMaster"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Kimbe MacMaster</p>
                </Link>
              </Col>                                                                      
            </Row>
            <Row>
              <Col lg={2} xs={4}>
                <Link to="#margot-mazur" title="Margot Mazur" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/margot-mazur.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Margot Mazur"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Margot Mazur</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#sam-hurley" title="Sam Hurley" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/sam-hurley.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Sam Hurley"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Sam Hurley</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#moosa-hemani" title="Moosa Hemani" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/moosa-hemani.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Moosa Hemani"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Moosa Hemani</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#cyrus-shepard" title="Cyrus Shepard" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/cyrus-shepard.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Cyrus Shepard"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Cyrus Shepard</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#joe-elliott" title="Joe Elliott" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/joe-elliott.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Joe Elliott"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Joe Elliott</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#lou-bortone" title="Lou Bortone" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/lou-bortone.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Lou Bortone"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Lou Bortone</p>
                </Link>
              </Col>                                                                      
            </Row>
            <Row>
              <Col lg={2} xs={4}>
                <Link to="#mordecai-holtz" title="Mordecai Holtz" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/mordecai-holtz.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Mordecai Holtz"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Mordecai Holtz</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#tim-ryan" title="Tim Ryan" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/tim-ryan.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Tim Ryan"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Tim Ryan</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#sujan-patel" title="Sujan Patel" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/sujan-patel.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Sujan Patel"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Sujan Patel</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#johnathan-dane" title="Johnathan Dane" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/johnathan-dane.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Johnathan Dane"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Johnathan Dane</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#shanelle-mullin" title="Shanelle Mullin" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/shanelle-mullin.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Shanelle Mullin"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Shanelle Mullin</p>
                </Link>
              </Col>
              <Col lg={2} xs={4}>
                <Link to="#cameron-seher" title="Cameron Seher" className="experts-link text-center">
                  <StaticImage
                    src="../../images/explainer-video/cameron-seher.jpeg"
                    placeholder="blurred"
                    width={100}
                    height={100}
                    alt="Cameron Seher"
                    className="rounded-circle"
                  />
                  <p className="expert-name">Cameron Seher</p>
                </Link>
              </Col>                                                                      
            </Row>                        
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvExperts
