import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EvWhereToPlace = () => {
  return (
    <section className="where-to-place-ev">
      <Container>
        <h2 className="text-center" id="uses">
          What’s An Explainer Video?
        </h2>
        <Row className="flex-lg-row-reverse">
          <Col lg={6}>
            <StaticImage
              src="../../images/explainer-video/ways-to-use-explainer-videos.png"
              width={450}
              height={250}
              alt=""
              placeholder="blurred"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <p>
              Many B2B businesses today are using explainer videos in all sorts
              of creative ways to boost conversions, widen engagement and
              increase sales. So now you need to know how you can use them for
              growing your business.
            </p>
            <p>
              Here's where to place your explainer videos and some of the
              different ways you can use them.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EvWhereToPlace
